import React from "react";

function About({ content }) {
  const linkStyle = {
    color: "#010F7A",
    textDecoration: "none",
  };
  return (
    <div>
      <h1>Vishal Shenoy</h1>
      <p>
        I am a student at UC Davis studying Computer Science and Mathematics.
      </p>
      <p>
        I enjoy building software and working on difficult technical problems
        with a consumer-facing approach.
      </p>
      <p>
        This summer, I am working on software for AI hardware at{" "}
        <a href="https://tenstorrent.com" target="_blank" style={linkStyle}>
          Tenstorrent
        </a>
        . Prior to that, I built automated tools for talent management at{" "}
        <a href="https://conviction.com" target="_blank" style={linkStyle}>
          Conviction
        </a>
        .
      </p>
    </div>
  );
}

export default About;

