import React from "react";
import styled from "styled-components";
import { Button } from "@react95/core";

const StyledLink = styled.a`
  margin-bottom: 10px;
  margin-left: -5px;
`;

function Resume({ content }) {
  const linkStyle = {
    color: "#010F7A",
    textDecoration: "none",
  };

  return (
    <div>
      <h2>Research</h2>
      <div>
        <p>
          <b>MATRIKS Trinity</b>
        </p>
        <i>
          UC Davis, Human/Robotics/Vehicle Integration & Performance (HRVIP) Lab
        </i>
        <p>
          Game theory-based simulations for a NASA project researching the
          neuroscience behind astronaut EDL training for Mars missions.
        </p>
      </div>
      <div>
        <p>
          <b>Vascular1</b>
        </p>
        <i>Stanford University, Department of Vascular Surgery</i>
        <p>
          A virtual reality application designed to train surgical residents in
          ultrasound guided vascular access (UGVA) procedures. Watch the video
          demonstration{" "}
          <a
            className="link"
            href="https://youtu.be/Ml7PMBWOpJc?si=nnr09mE2EC8CUqYC"
            target="_blank"
            style={linkStyle}
          >
            here
          </a>
          .
        </p>
        <a
          className="link"
          href="https://doi.org/10.1109/SSCI50451.2021.9659919"
          target="_blank"
          style={linkStyle}
        >
          IEEE Paper
        </a>
        {" | "}
        <a
          className="link"
          href="https://doi.org/10.1016/j.jvs.2021.06.385"
          target="_blank"
          style={linkStyle}
        >
          JVS Abstract
        </a>
        {" | "}
        <a
          className="link"
          href="http://doi.org/10.1016/j.jamcollsurg.2021.07.679"
          target="_blank"
          style={linkStyle}
        >
          JACS Abstract
        </a>
      </div>
      <div>
        <p>
          <b>CovXR</b>
        </p>
        <i>Stanford University, Department of Radiology</i>
        <p>
          A machine learning algorithm for detecting COVID-19 induced pneumonia
          features on chest radiographs.
        </p>
        <a
          className="link"
          href="https://doi.org/10.1109/SSCI50451.2021.9659919"
          target="_blank"
          style={linkStyle}
        >
          IEEE Paper
        </a>
        {" | "}
        <a
          className="link"
          href="https://arxiv.org/pdf/2110.06398"
          target="_blank"
          style={linkStyle}
        >
          arXiv Preprint (PDF)
        </a>
      </div>
      <br></br>
      <StyledLink
        href="https://scholar.google.com/citations?user=iDeVMC0AAAAJ&hl=en&oi=ao"
        target="_blank"
      >
        <Button style={{ fontSize: "14px" }} className="pointer">
          Google Scholar
        </Button>
      </StyledLink>
      <br></br>
      <br></br>
    </div>
  );
}

export default Resume;
