import React from "react";
import { Button } from "@react95/core";
import styled from "styled-components";

const StyledLink = styled.a`
  margin-bottom: 10px;
`;
const linkStyle = {
  color: "#010F7A",
  textDecoration: "none",
};

function Projects({ content }) {
  return (
    <div>
      <h2>Projects</h2>
      <div>
        <p>
          <b>Animanic</b>
        </p>
        <p>
          A text-to-video generation platform to transform any topic into an
          engaging, interactive video.
        </p>
        {/* <p>
          Languages + Tools: <i>Python, Streamlit, Pandas, Raspberry Pi OS</i>
  </p>*/}
        <a href="https://animanic.site" target="_blank" style={linkStyle}>
          Website
        </a>
        {" | "}
        <a
          href="https://www.youtube.com/watch?v=KO793dX0Mso"
          target="_blank"
          style={linkStyle}
        >
          Demo
        </a>{" "}
        <br></br>
      </div>
      <div>
        <p>
          <b>tldw</b>
        </p>
        <p>
          A video summarization platform utilizing LLM and speech recognition
          technology. Includes embedded notes.
        </p>
        {/*<p>
          Languages + Tools:{" "}
          <i>Python, JavaScript, React, OpenAI GPT-4, Whisper</i>
</p>*/}
        <a href="https://tldw.xyz/" target="_blank" style={linkStyle}>
          Website
        </a>{" "}
        |{" "}
        <a
          href="https://www.youtube.com/watch?v=h1daCgDtizM"
          target="_blank"
          style={linkStyle}
        >
          Demo
        </a>
        <br></br>
      </div>
      <div>
        <p>
          <b>Eleven</b>
        </p>
        <p>
          A semantic search engine for UC Davis undergraduate classes,
          displaying results within milliseconds.{" "}
        </p>
        {/*<p>
          Languages + Tools:{" "}
          <i>Python, JavaScript, React, BeautifulSoup, S-BERT</i>
</p>*/}
        <a href="https://useeleven.info/" target="_blank" style={linkStyle}>
          Website
        </a>{" "}
        |{" "}
        <a
          href="https://www.youtube.com/watch?v=QOHvbyZjVDM"
          target="_blank"
          style={linkStyle}
        >
          Demo
        </a>
        <br></br>
      </div>
      <div>
        <p>
          <b>coverart.lol</b>
        </p>
        <p>
          A generative AI web application to create Spotify playlist cover art.
        </p>
        {/*<p>
          Languages + Tools: <i>TypeScript, React, NodeJS, DALL-E</i>
</p>*/}
        <a href="https://www.coverart.lol/" target="_blank" style={linkStyle}>
          Website
        </a>{" "}
        |{" "}
        <a
          href="https://www.youtube.com/watch?v=HKn7CoC47WQ"
          target="_blank"
          style={linkStyle}
        >
          Demo
        </a>
        <br></br>
      </div>
      <div>
        <p>
          <b>Ghiblify</b>
        </p>
        <p>
          {" "}
          A fine-tuned Stable Diffusion pipeline to customize photos into Studio
          Ghibli style artwork.{" "}
        </p>
        {/* <p>
          Languages + Tools: <i>Python, JavaScript, React, Stable Diffusion</i>
</p> */}
        <a
          href="https://github.com/vishalshenoy/ghiblify"
          target="_blank"
          style={linkStyle}
        >
          GitHub
        </a>{" "}
        |{" "}
        <a
          href="https://youtu.be/ouv5B2Yr8wM"
          target="_blank"
          style={linkStyle}
        >
          Demo
        </a>
        <br></br>
      </div>
      <div>
        <p>
          <b>DropDoc</b>
        </p>
        <p>
          An LLM-based document chatbot with support for multiple files and
          types.
        </p>
        {/*<p>
          Languages + Tools:{" "}
          <i>Python, JavaScript, React, LangChain, ChromaDB</i>
</p>*/}
        <a
          href="https://github.com/vishalshenoy/dropdoc"
          target="_blank"
          style={linkStyle}
        >
          GitHub
        </a>{" "}
        |{" "}
        <a
          href="https://www.youtube.com/watch?v=82PotBdQwyI"
          target="_blank"
          style={linkStyle}
        >
          Demo
        </a>
        <br></br>
      </div>
      <div>
        <p>
          <b>AIWork</b>
        </p>
        <p>
          A platform to generate customized workout plans based on experience
          and fitness goals.
        </p>
        {/*<p>
          Languages + Tools:{" "}
          <i>Python, JavaScript, React, BeautifulSoup, S-BERT</i>
</p>*/}
        <a
          href="https://github.com/vishalshenoy/workouts"
          target="_blank"
          style={linkStyle}
        >
          GitHub
        </a>{" "}
        |{" "}
        <a
          href="https://www.youtube.com/watch?v=azdWxZ5vYAk"
          target="_blank"
          style={linkStyle}
        >
          Demo
        </a>
        <br></br>
      </div>
      <div>
        <p>
          <b>iSpy</b>
        </p>
        <p>
          A published mobile app that identifies common objects through a phone
          camera.{" "}
        </p>
        {/*<p>
          Languages + Tools: <i>Swift, CoreML</i>
</p>*/}
        <a
          href="https://apps.apple.com/us/app/ispy-image-classifier-game/id1550525013"
          target="_blank"
          style={linkStyle}
        >
          App Store
        </a>
        <br></br>
      </div>
      <div>
        <p>
          <b>TossIt</b>
        </p>
        <p>
          A computer game to teach kids how to properly dispose of trash,
          recycling, and compost.
        </p>
        {/*<p>
          Languages + Tools: <i>Swift, AVFoundation, SpriteKit</i>
</p>*/}
        <a
          href="https://www.wwdcscholars.com/s/C426FC6E-5B38-4C97-AE51-1C6C798D906D/2021"
          target="_blank"
          style={linkStyle}
        >
          Apple Scholars
        </a>
        <br></br>
      </div>
      <div>
        <p>
          <b>Business Class</b>
        </p>
        <p>A three dimensional plane trivia game to study business trivia.</p>
        {/*<p>
          Languages + Tools: <i>Swift, AVFoundation, SpriteKit</i>
</p>*/}
        <a
          href="https://github.com/vishalshenoy/business-class"
          target="_blank"
          style={linkStyle}
        >
          GitHub
        </a>
        <br></br>
      </div>
      <div>
        <p>
          <b>Space Alien</b>
        </p>
        <p>
          A two dimensional UFO blaster video game I built as a follow up to
          Jumpy Alien when I was twelve years old.
        </p>
        {/*<p>
          Languages + Tools: <i>Swift, AVFoundation, SpriteKit</i>
</p>*/}
        <a
          href="https://tetracyl.itch.io/space-alien"
          target="_blank"
          style={linkStyle}
        >
          Website
        </a>
        <br></br>
      </div>
      <div>
        <p>
          <b>Jumpy Alien</b>
        </p>
        <p>
          A two dimensional platformer game I built when I was eleven years old.
        </p>
        {/*<p>
          Languages + Tools: <i>Swift, AVFoundation, SpriteKit</i>
</p>*/}
        <a
          href="https://tetracyl.itch.io/jumpy-alien"
          target="_blank"
          style={linkStyle}
        >
          Website
        </a>
        <br></br>
      </div>
      <br></br>
    </div>
  );
}

export default Projects;


